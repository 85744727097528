<template>
    <div class="commodityManagement">
        <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item style="font-weight: bold;">店铺客服管理</el-breadcrumb-item>
                <el-breadcrumb-item>客服商品管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="commodityManagement-header">
            <IndustrySelection @selectListChange="selectListChange" :selectList="selectList" text="行业分类："></IndustrySelection>
            <div>
                <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>
                <router-link :to="{name:'serviceCmmodityCreation'}"><el-button class="newClientBtn">创建客服商品</el-button></router-link>
            </div>
        </div>
        <div class="commodityManagement-table">
            <el-table  ref="multipleTable" :data="showGoodsList" border style="margin-top:20px;width: 100%;flex: 1;border: #EEEEEE 1px solid;height:calc(100vh - 260px);overflow-y: auto" :cell-style="{color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: 500, color: '#14141C', background: '#F3F4FA',height: '60px'}">
                <el-table-column type="selection" width="50" align="center"></el-table-column>
                <el-table-column prop="goods_name" label="商品名称" align="center">
                    <template slot-scope="scope">
                        <span class="text-overflow-2" style="text-align: left">{{scope.row.goods_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="cover" label="商品主图" align="center">
                    <template slot-scope="scope" v-if="scope.row.goods_images.length">
                        <img style="height: 38px;width: 38px;" :src="scope.row.goods_images[0].src" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="category_name" label="行业分类" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1122D8" @click="editShowGoods(scope.row)">编辑</el-button>
                        <el-button type="text" style="color: #FF0000" @click="deteleShowGoods(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    import { sendKeFuGoodsAPI } from "../../../utils/apis"
    import IndustrySelection from "../../admin/IndustrySelection";

    export default {
        name: "commodityManagement",
        components: {
            IndustrySelection
        },
        data(){
            return {
                showGoodsList:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                selectList: [],
                selectCategory: void 0,
            }
        },
        created() {
            this.getShowGoods();
            this.getCategoryConfig();
        },
        activated() {
            this.getShowGoods(this.selectCategory);
        },
        methods: {
            async sendBtn(){
                let id =this.$refs.multipleTable.selection.map((item) => item.goods_id);
                if(id && id.length > 0){
                  const result = await this.$common.sendData(sendKeFuGoodsAPI, { ids: id });
                  result && this.getShowGoods(this.selectCategory);
                }else{
                  this.$message.error("请勾选需要发送的数据！")
                }
            },
            selectListChange(id) {
                this.selectCategory = id;
                this.getShowGoods(id);
            },
            // 获取行业分类
            getCategoryConfig() {
                this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
                    if (res.code === 200) {
                        this.selectList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            },
            getShowGoods(id){
                let param = {
                    page:this.adminPages.currentPageNum,
                    limit:this.adminPages.eachPageNum,
                    category_id: id ? id : ''
                }
              this.$http.axiosGetBy(this.$api.showGoods, param, res=>{
                  if(res.code === 200){
                      this.showGoodsList = res.data.data;
                      this.adminPages.total = res.data.total
                  } else {
                      this.$message.warning(res.msg)
                  }
              })
            },
            editShowGoods(row){
              let editData = JSON.stringify(row);
              this.$router.push({
                  path:'/admin/storecustomer/servicecmmoditycreation',
                  query:{
                      res:editData,
                  }
              })
            },
            deteleShowGoods(row){
                this.$confirm('此操作将永久删除该商品，是否继续？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$http.axiosGetBy(this.$api.delGoods, {'goods_id':row.goods_id}, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                                duration:1000,
                                onClose:()=>{
                                    this.getShowGoods(this.selectCategory);
                                }
                            });

                        } else {
                            this.$message(res.msg)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getShowGoods(this.selectCategory);
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-breadcrumb__item .el-breadcrumb__inner{
        color: #303133;
    }
    .commodityManagement-table{
        /deep/th{
            padding: 0!important;
            height: 60px;
            line-height: 60px;
        }
        /deep/td{
            padding: 0!important;
            height: 60px;
            line-height: 60px;
        }
    }
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .commodityManagement{
        padding: 30px;
        height: 100%;
        .commodityManagement-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            .el-breadcrumb {
                line-height: 40px;
            }
            .newClientBtn{
                width: 120px;
                color: #ffffff;
                background: #1122D8;
                border-radius:4px;
                border: none;
                margin-left: 10px;
            }
        }
    }
</style>